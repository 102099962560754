import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Instagram } from 'react-feather';

import { INSTAGRAM_PROFILE } from '../../config/utils';

const Footer = () => (
  <Row className="web-layout-footer small text-uppercase mt-4">
    <Col md={{ span: 10, offset: 1 }} className="align-self-center">
      <Row className="web-layout-footer__container">
        <Col md={7}>
          <p className="m-0">© 2024 TOT RIDERS. Todos los derechos reservados</p>
        </Col>
        <Col md={5} className="justify-content-md-end">
          <p className="m-0">Síguenos en Instagram</p>
          <Button
            variant="circle-white"
            className="no-shadow no-border"
            onClick={() => window.open(INSTAGRAM_PROFILE, '_blank', 'noopener')}
          >
            <Instagram />
          </Button>
        </Col>
      </Row>
    </Col>
  </Row>
);

export default Footer;

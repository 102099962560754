import React from 'react';
import { Image } from 'react-bootstrap';

import { WHATSAPP_LINK } from '../../../config/utils';
import whatsAppLogo from './whatsapp-logo.svg';

const WhatsAppButton = () => (
  <a href={WHATSAPP_LINK} target="_blank" rel="noopener noreferrer" className="whatsapp-button">
    <Image width="60" height="60" src={whatsAppLogo} alt="whatsapp" />
  </a>
);

export default WhatsAppButton;

import React from 'react';
import { Row } from 'react-bootstrap';

const PageHeader = ({ children, className, style }) => (
  <Row className={`page-header ${className || ''}`} style={style}>
    {children}
  </Row>
);

export default PageHeader;

import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { ChevronsRight } from 'react-feather';
import { Link } from 'react-scroll';
import PropTypes from 'prop-types';

const ServicePlanCard = ({ btnCircle, btn, disabled, icon, items, price, title, variant }) => (
  <Card className="card-shadow service-plan-card">
    <Card.Header className={`bg-${variant}`}>
      {icon && <div className="my-2">{icon}</div>}
      <h5 className="my-2">{title}</h5>
    </Card.Header>
    <Card.Body>
      <Row className="mb-4">
        <Col xs={12} className="card-body__title">
          {price ? (
            <>
              <p className="mb-0 mr-3">desde</p>
              <h3 className="mb-0">{price}</h3>
            </>
          ) : (
            <Link to="contact" spy={true} smooth={true} offset={-100} duration={500}>
              <span className={`text-${variant} cursor-pointer`}>Contacto</span>
            </Link>
          )}
        </Col>
        {items.length > 0 && (
          <Col xs={12} className="card-body__item">
            <ul className="list-group">
              {items.map((item, index) => (
                <li key={`service-plan-${index.toString()}`} className="list-group-item">
                  <div>
                    <ChevronsRight className={`mr-2 w-auto text-${variant}`} />
                    {item}
                  </div>
                </li>
              ))}
            </ul>
          </Col>
        )}
      </Row>
      {btn.active && (
        <Row className="card-body__button">
          <Col xs={4}>
            <Button
              block
              variant={variant}
              disabled={disabled}
              className={`card-shadow ${btn.circle ? 'btn-circle' : ''}`}
              onClick={btn.onClick}
            >
              {btn.icon || btn.text}
            </Button>
          </Col>
        </Row>
      )}
    </Card.Body>
  </Card>
);

ServicePlanCard.propTypes = {
  btn: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  items: PropTypes.array,
  price: PropTypes.string,
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

ServicePlanCard.defaultProps = {
  btn: { active: false, circle: false },
  disabled: false,
  items: [],
  variant: 'primary',
};

export default ServicePlanCard;

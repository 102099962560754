import React from 'react';
import { Container, Image, Nav, Navbar as BasicNavbar } from 'react-bootstrap';
import { Menu } from 'react-feather';
import { Link } from 'react-scroll';

import logo from '../../assets/images/white-logo-1.png';

const Navbar = () => {
  return (
    <BasicNavbar expand="lg" className="bg-body-tertiary">
      <Container>
        <BasicNavbar.Brand href="/">
          <Image src={logo} alt="logo" />
        </BasicNavbar.Brand>
        <BasicNavbar.Toggle aria-controls="basic-navbar-nav">
          <Menu />
        </BasicNavbar.Toggle>
        <BasicNavbar.Collapse id="basic-navbar-nav">
          <Nav>
            <Link
              className="nav-link"
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              <span className="pb-1">Inicio</span>
            </Link>
            <Link
              className="nav-link"
              activeClass="active"
              to="about-us"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              <span className="pb-1">Nosotros</span>
            </Link>
            <Link
              className="nav-link"
              activeClass="active"
              to="service-plan"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              <span className="pb-1">Servicios</span>
            </Link>
            <Link
              className="nav-link"
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              <span className="pb-1">Contacto</span>
            </Link>
          </Nav>
        </BasicNavbar.Collapse>
      </Container>
    </BasicNavbar>
  );
};

export default Navbar;

import React from 'react';
import { Col, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Loader = ({ animation, variant }) => (
  <Col className="custom-spinner">
    <Spinner animation={animation} variant={variant} />
  </Col>
);

Loader.propTypes = {
  animation: PropTypes.string,
  variant: PropTypes.string,
};

Loader.defaultProps = {
  animation: 'border', // border, grow
  variant: 'primary',
};

export default Loader;

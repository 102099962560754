import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import { excludeKeys } from '../../../services/utils';

const FormikTextarea = ({ error, helpText, touched, ...props }) => {
  const { abbr, className, disabled, label, placeholder, rows, size } = props;
  const necessaryProps = excludeKeys(props, ['abbr']);

  return (
    <Form.Group className={`${error && touched ? 'is-invalid' : ''} ${disabled ? 'is-disabled' : ''}`}>
      {label && (
        <Form.Label>
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
        </Form.Label>
      )}
      <Form.Control
        as="textarea"
        size={size}
        rows={rows}
        placeholder={placeholder}
        className={className}
        {...necessaryProps}
      />
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormikTextarea.propTypes = {
  abbr: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  rows: PropTypes.number,
};

FormikTextarea.defaultProps = {
  abbr: false,
  className: '',
  label: '',
  rows: 3,
};

export default FormikTextarea;

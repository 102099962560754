import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const AccordionBtn = ({ items, variant }) => (
  <Accordion defaultActiveKey="0">
    {items.map((item, index) => (
      <Card className="box" key={index.toString()}>
        <Card.Header className="px-2">
          <Accordion.Toggle block as={Button} variant={variant} eventKey={index.toString()}>
            {item.title}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={index.toString()}>
          <Card.Body>{item.body}</Card.Body>
        </Accordion.Collapse>
      </Card>
    ))}
  </Accordion>
);

AccordionBtn.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

AccordionBtn.defaultProps = {
  variant: 'primary',
};

export default AccordionBtn;

import React from 'react';
import { Col, Image } from 'react-bootstrap';

import circleLogo from '../assets/images/circle-logo-2.png';
import { PageHeader } from '../components';

const Home = () => (
  <section>
    <ContentBox />
    <Wave />
  </section>
);

const ContentBox = () => (
  <PageHeader className="home-content-box">
    <Col lg={6} xl={7} className="home-content-box__text">
      <h1>¡Tu servicio de mensajería urgente de confianza!</h1>
    </Col>
    <Col lg={6} xl={5} className="home-content-box__image">
      <Image src={circleLogo} alt="home-1" />
    </Col>
  </PageHeader>
);

const Wave = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path
      fill="#2d0e7f"
      fillOpacity="1"
      d="M0,0L80,16C160,32,320,64,480,101.3C640,139,800,181,960,170.7C1120,160,1280,96,1360,64L1440,32L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
    ></path>
  </svg>
);

export default Home;

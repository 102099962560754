import React from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router } from 'react-router-dom';

import { Footer, Navbar, WhatsAppButton } from './app/components';
import { AboutUs, ContactIndex, Home, ServicePlan } from './app/pages';

import './index.scss';

const App = () => {
  return (
    <Router>
      <Navbar />
      <Container fluid className="web-layout-content">
        <AppSection id="home" component={Home} />
        <AppSection id="about-us" component={AboutUs} />
        <AppSection id="service-plan" component={ServicePlan} />
        <AppSection id="contact" component={ContactIndex} />
        <WhatsAppButton />
      </Container>
      <Footer />
    </Router>
  );
};

const AppSection = ({ id, component: Component }) => (
  <div id={id} style={{ minHeight: '80vh' }}>
    <Component />
  </div>
);

export default App;

import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { XCircle } from 'react-feather';
import PropTypes from 'prop-types';

const ModalConfirm = ({
  body,
  handleClose,
  handleConfirm,
  show,
  title,
  titleBtnClose,
  titleBtnSave,
  variantBtnClose,
  variantBtnSave,
  withButtons,
  ...props
}) => (
  <Modal {...props} show={show} onHide={handleClose}>
    {title && (
      <Modal.Header>
        <Modal.Title className="capitalize-text">
          <h6>{title}</h6>
        </Modal.Title>
        <XCircle variant="transparent" className="black" onClick={handleClose} />
      </Modal.Header>
    )}
    <Modal.Body>{body}</Modal.Body>
    {withButtons && (
      <Modal.Footer className="mt-4">
        <Button variant={variantBtnClose} className="no-shadow" onClick={handleClose}>
          {titleBtnClose}
        </Button>
        <Button type="submit" variant={variantBtnSave} className="no-shadow ml-4" onClick={handleConfirm}>
          {titleBtnSave}
        </Button>
      </Modal.Footer>
    )}
  </Modal>
);

ModalConfirm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  titleBtnClose: PropTypes.string,
  titleBtnSave: PropTypes.string,
  variantBtnClose: PropTypes.string,
  variantBtnSave: PropTypes.string,
};

ModalConfirm.defaultProps = {
  titleBtnClose: 'Cancelar',
  titleBtnSave: 'Confirmar',
  variantBtnClose: 'cancel',
  variantBtnSave: 'submit',
};

const objectOrFunction = (component, props) => {
  if (typeof component === 'function') {
    return component(props);
  }
  return component;
};

const CenteredModal = ({ body, centered, closeButton, dialogClassName, onHide, size, subtitle, title, ...props }) => (
  <Modal {...props} dialogClassName={size ? '' : dialogClassName} size={size} centered={centered} onHide={onHide}>
    {closeButton && (
      <XCircle onClick={onHide} className="cursor-pointer" style={{ position: 'absolute', right: 20, top: 25 }} />
    )}
    {(title || subtitle) && (
      <Modal.Header className="d-flex align-items-center justify-content-between border-0">
        <Modal.Title className="capitalize-text">
          <h6 className="mb-0">{title}</h6>
          {subtitle && <h6 className="mb-0">{subtitle}</h6>}
        </Modal.Title>
      </Modal.Header>
    )}
    <Modal.Body className="modal-body-padding">{objectOrFunction(body)} </Modal.Body>
  </Modal>
);

CenteredModal.propTypes = {
  size: PropTypes.string,
  closeButton: PropTypes.bool,
  centered: PropTypes.bool,
};

CenteredModal.defaultProps = {
  size: 'md',
  closeButton: true,
  centered: true,
};

export default CenteredModal;

export { ModalConfirm, CenteredModal };

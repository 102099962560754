import React, { useState } from 'react';
import { Col, Image } from 'react-bootstrap';
import { Map, Maximize2, Package, Calendar } from 'react-feather';

import valenciaInImg from '../assets/images/utils/valencia-in.png';
import valenciaOutImg from '../assets/images/utils/valencia-out.png';
import { CenteredModal, PageHeader, ServicePlanCard } from '../components';

const ServicePlan = () => {
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const handleModalContent = ({ standard }) => {
    const title = standard ? 'Estándar' : 'Distancia especial';
    const srcImg = standard ? valenciaInImg : valenciaOutImg;
    setModalShow(true);
    setModalTitle(title);
    setModalBody(<Image fluid rounded src={srcImg} alt="map-img" />);
  };

  return (
    <PageHeader>
      <Col md={12}>
        <h4 className="text-uppercase text-center mb-4 font-weight-bold">Servicios</h4>
      </Col>
      <Col sm={6} md={5} xl={4}>
        <ServicePlanCard
          btn={{
            active: true,
            circle: true,
            icon: <Map size={20} />,
            onClick: () => handleModalContent({ standard: true }),
          }}
          variant="primary-soft"
          icon={<Package size={50} />}
          title="Estándar"
          items={['Servicio dentro de la zona señalada de Valencia.']}
        />
      </Col>
      <Col sm={6} md={5} xl={4}>
        <ServicePlanCard
          btn={{
            active: true,
            circle: true,
            icon: <Map size={20} />,
            onClick: () => handleModalContent({ standard: false }),
          }}
          variant="secondary"
          icon={<Maximize2 size={50} />}
          title="Distancia especial"
          items={['Servicio fuera de la zona señalada de Valencia.']}
        />
      </Col>
      <Col sm={6} md={5} xl={4}>
        <ServicePlanCard
          variant="info"
          icon={<Calendar size={50} />}
          title="Personalizado"
          items={['Servicio especial que se ajusta a las necesidades del cliente.']}
        />
      </Col>

      <CenteredModal show={modalShow} title={modalTitle} body={modalBody} onHide={() => setModalShow(false)} />
    </PageHeader>
  );
};

export default ServicePlan;

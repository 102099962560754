import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import { excludeKeys } from '../../../services/utils';

const FormikInput = ({ error, helpText, touched, ...props }) => {
  const { abbr, className, disabled, inputType, label, placeholder, size } = props;
  const necessaryProps = excludeKeys(props, ['abbr']);

  return (
    <Form.Group className={`${error && touched ? 'is-invalid' : ''} ${disabled ? 'is-disabled' : ''}`}>
      {label && (
        <Form.Label>
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
        </Form.Label>
      )}
      <Form.Control type={inputType} size={size} placeholder={placeholder} className={className} {...necessaryProps} />
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormikInput.propTypes = {
  abbr: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
};

FormikInput.defaultProps = {
  abbr: false,
  className: '',
  label: '',
};

export default FormikInput;

const excludeKeys = (object, excludes) => {
  // const copyObject = JSON.parse(JSON.stringify(object)); // Esta opción no copia funciones (onBlur, onChange, ...)
  const copyObject = Object.assign({}, object);
  excludes.map(key => delete copyObject[key]);
  return copyObject;
};

const groupBy = (array, attribute, optionalAttribute = null) => {
  return array.reduce((acc, current) => {
    const { [`${attribute}`]: key, [`${optionalAttribute}`]: optionalKey } = current;
    const vKey = key || optionalKey;
    if (acc.includes(acc.find(obj => Object.keys(obj).includes(vKey)))) {
      acc.find(obj => Object.keys(obj).includes(vKey) && obj[vKey].push(current));
    } else {
      acc.push({ [vKey]: [current] });
    }
    return acc;
  }, []);
};

const isObject = v => typeof v === 'object';

const toInteger = number => parseInt(number, 0);

export { excludeKeys, groupBy, isObject, toInteger };

import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';

import missionImg from '../assets/images/mission.png';
import visionImg from '../assets/images/vision.png';
import valuesImg from '../assets/images/values.png';
import { PageHeader } from '../components';

const AboutUs = () => {
  return (
    <PageHeader className="about-us">
      <Col md={12} className="about-us__us">
        <h4>Nosotros</h4>
        <p>
          En Tot Rider's queremos mejorar tu experiencia con el Servicio de Mensajería Urgente, prestando un servicio de
          Calidad, 100% personalizado y con precios competitivos.
        </p>
      </Col>
      <Col md={12} className="about-us__mission">
        <Row className="parent">
          <Col md={6}>
            <h4>Misión</h4>
            <p>
              Nuestra misión es entregar tu “Mensajería Urgente” eficientemente y eficaz, demostrando con nuestro
              servicio que tu tranquilidad y confianza es lo más importante para nosotros.
            </p>
          </Col>
          <Col md={6} className="about-us__images">
            <Image src={missionImg} alt="mission" className="d-flex w-50" />
          </Col>
        </Row>
      </Col>
      <Col md={12} className="about-us__vision">
        <Row className="parent">
          <Col md={6}>
            <h4>Visión</h4>
            <p>Ser la empresa de vanguardia en el Mercado de Servicio de Mensajería Urgente.</p>
          </Col>
          <Col md={6} className="about-us__images">
            <Image src={visionImg} alt="mission" className="d-flex w-50" />
          </Col>
        </Row>
      </Col>
      <Col md={12} className="about-us__our-values">
        <Row className="parent">
          <Col>
            <h4>Nuestros Valores</h4>
            <ul>
              <li>La Confianza</li>
              <li>El Profesionalismo</li>
              <li>El Compromiso con Nuestros Clientes</li>
              <li>La Eficiencia y Eficacia</li>
              <li>El Respeto</li>
              <li>La Puntualidad</li>
            </ul>
          </Col>
          <Col md={6} className="about-us__images">
            <Image src={valuesImg} alt="mission" className="d-flex w-50" />
          </Col>
        </Row>
      </Col>
    </PageHeader>
  );
};

export default AboutUs;

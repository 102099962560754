import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Field, Form, getIn, withFormik } from 'formik';
import * as Yup from 'yup';

import { FormikInput, FormikTextarea } from '../../components';

const ContactForm = ({ errors, isSubmitting, touched }) => (
  <Form>
    <Row>
      <Col md={12}>
        <Field name="contact[fromName]">
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              label="Nombre"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={12}>
        <Field name="contact[replyTo]">
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              label="Correo Electrónico"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={12}>
        <Field name="contact[message]">
          {({ field }) => (
            <FormikTextarea
              {...field}
              abbr
              label="Mensaje"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              className="no-resizing"
            />
          )}
        </Field>
      </Col>
    </Row>
    <Row>
      <Col>
        <Button block type="submit" variant="primary-soft" disabled={isSubmitting}>
          Enviar
        </Button>
      </Col>
    </Row>
  </Form>
);

const setInitialValues = ({ contact }) => ({ contact });

const validationSchema = Yup.object().shape({
  contact: Yup.object().shape({
    fromName: Yup.string().required('Debes ingresar un nombre'),
    message: Yup.string().required('Debes ingresar un mensaje'),
    replyTo: Yup.string()
      .required('Debes ingresar un correo electrónico')
      .email('El correo electrónico ingresado no es válido'),
  }),
});

const handleSubmit = (values, { props, setSubmitting, resetForm }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting, resetForm);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
})(ContactForm);
